import React from "react";
import HeaderScreen from "./HeaderScreen";

import CampaignsList from "./CampaignsList";

function LandingScreen() {

  return (
    <div className="flex flex-col pt-4 w-full">

      <HeaderScreen/>

      <div className="flex flex-col px-4 py-4 w-full">
        
        <CampaignsList/>

      </div>
    </div>
  );
}

export default LandingScreen;
