import axios from "axios";
import { Constants } from "../Shared";


class SystemService {

    async fetchSystemLogs(){
        return axios.get(`${Constants.baseUrl}/system/logs`);
    }
}

export default SystemService;