import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { FiUser, FiMenu, FiX } from "react-icons/fi";

import { UserContext } from "./Contexts";

import lomiIcon from "./assets/lomi-icon.png";

import { TipsScreen, LandingScreen, AuthenticationScreen, PdfViewerScreen, PostNotificationScreen, ImageViewer, 
  ReportsScreen, TransactionsScreen, CharitiesScreen, CreateCampaignScreen, LogScreen, WithdrawScreen } from "./screens";

import { AppBar } from "./screens/shared";

import { UserService } from "./Services";

function App() {
  const userService = new UserService();

  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  // const [showAppBar, setShowAppBar] = useState(false);

  const [userChecked, setUserChecked] = useState(false);

  useEffect(() => {
    // const token = Cookies.get('token');
    // Get user data and check if it is valid
    userService.fetchUserData()
    .then((res) => {
      // console.log("---> Response: \n");
      // console.log(res.data);
      setUser(res.data.data);
      setLoggedIn(true);
    })
    .catch((err) => console.log("---> Error: \n" + err))
    .finally(() => {
      setUserChecked(true);
    });
  // eslint-disable-next-line
  }, []);
 
  return (
    <div className="flex flex-col items-center min-h-screen w-full">
      {userChecked 
      ? (
        <div className="flex-grow w-full min-h-screen">
          <UserContext.Provider value={{user, setUser, loggedIn, setLoggedIn}}>
            <Router className='min-h-screen'>
              <div className="flex flex-col min-h-screen">
                <div className="flex flex-row flex-grow">
                  {(loggedIn && user) && <AppBar/>}
                  <Routes>
                    <Route path="" element={<PrivateRoute loggedIn={loggedIn} user={user}> <LandingScreen /> </PrivateRoute>}/>
                    <Route path="document" element={<PrivateRoute loggedIn={loggedIn} user={user}> <PdfViewerScreen /> </PrivateRoute>}/>
                    <Route path="doc/:id" element={<PrivateRoute loggedIn={loggedIn} user={user}> <ImageViewer /> </PrivateRoute>}/>
                    <Route path="notification" element={<PrivateRoute loggedIn={loggedIn} user={user}> <PostNotificationScreen /> </PrivateRoute>}/>
                    <Route path="tips" element={<PrivateRoute loggedIn={loggedIn} user={user}> <TipsScreen /> </PrivateRoute>}/>
                    <Route path="reports" element={<PrivateRoute loggedIn={loggedIn} user={user}> <ReportsScreen /> </PrivateRoute>}/>
                    <Route path="transactions" element={<PrivateRoute loggedIn={loggedIn} user={user}> <TransactionsScreen /> </PrivateRoute>}/>
                    <Route path="charity" element={<PrivateRoute loggedIn={loggedIn} user={user}> <CharitiesScreen /> </PrivateRoute>}/>
                    <Route path="createcampaign" element={<PrivateRoute loggedIn={loggedIn} user={user}> <CreateCampaignScreen /> </PrivateRoute>}/>
                    <Route path="logs" element={<PrivateRoute loggedIn={loggedIn} user={user}> <LogScreen /> </PrivateRoute>}/>
                    <Route path="withdraws" element={<PrivateRoute loggedIn={loggedIn} user={user}> <WithdrawScreen /> </PrivateRoute>}/>
                    <Route path="login" element={<AuthenticationScreen/>}/>
                  </Routes>
                </div>
              </div>
            </Router>
          </UserContext.Provider>
        </div>
      ) 
      : (
        <div className="flex flex-col gap-2 w-full min-h-screen justify-center items-center">
          <img src={lomiIcon} alt="Lomi Icon" width={80}/>
          <div className="text-primary-blue text-lg">
            Admin Loading...
          </div>
        </div>
      )}
    </div>
  );
}

function PrivateRoute({ children, loggedIn, user }) {
  if (!loggedIn || !user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default App;
