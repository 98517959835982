import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class CharityService {

    // constructor(){
    //     this.token = userService.getStoredToken();
    //     this.config = {
    //         headers: { Authorization: `Bearer ${this.token}`, 'Content-Type':'application/json'}
    //     };
    // }

    fetchCharities = async () => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.get(`${Constants.baseUrl}/charity/`, config);
    }

    fetchCharityById = async (id) => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.get(`${Constants.baseUrl}/charity/byid/${id}`, config);
    }

    updateCharity = async (id, data) => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.put(`${Constants.baseUrl}/charity/update/${id}`, data, config);
    }

    createCharity = async (data) => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.post(`${Constants.baseUrl}/charity/create`, data, config);
    }

    deleteCharity = async (id) => {
        const token = await userService.getStoredToken();
        const config = {headers: { Authorization: `Bearer ${token}`}};
        return axios.delete(`${Constants.baseUrl}/charity/delete/${id}`, config);
    }
}

export default CharityService;