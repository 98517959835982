import React, {useState} from 'react';
import { FiX, FiPlus } from "react-icons/fi";

import { CharityService } from '../../Services';

function CreateCharity({onClose}) {

    const charityService = new CharityService();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [logo, setLogo] = useState(null);

    const createCharity = () => {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('image', logo);
        
        setLoading(true);
        charityService.createCharity(formData).then((res) => {
            console.log("Created");
            onClose();
        }).catch((err) => {
            console.log("Error" + err)
        }).finally(() => {
            setLoading(false);
        })
    }
    
    return (
        <div className="inset-0 absolute top-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className='flex flex-col items-center justify-center bg-white rounded-xl px-4 py-4 w-[500px]'>
                <div className="flex flex-row w-full justify-between items-center mb-6">
                    <div className="text-xl text-slate-900 font-medium"> Create Charity </div>
                    <button onClick={onClose} 
                    className="px-2 py-2"> 
                        <FiX size={20} color='#ff0000'/>
                    </button>
                </div>
                <div className='flex flex-col w-full'>
                    {loading && <div>Creating Charity...</div>}
                    <div className='flex flex-col mb-4'>
                        <div className='text-lg font-medium text-primary-blue'>
                            Logo:
                        </div>

                        {!logo && (
                            <div className="bg-slate-100 rounded-md px-4 py-3 w-60 h-40 flex justify-center items-center">
                                <FiPlus size={30} className="text-slate-400"/>
                            </div>
                        )}

                        {logo && (
                            <img alt="not fount" src={URL.createObjectURL(logo)} className="w-60 h-40 rounded-md object-cover" />
                        )}

                        <input
                            type="file"
                            name="myImage"
                            onChange={(event) => {
                                setLogo(event.target.files[0]);
                            }}
                        />
                    </div>
                    <div className='text-lg font-medium text-primary-blue mb-1'>
                        Charity Name:
                    </div>
                    <input type="text" value={name} onChange={(val) => setName(val.currentTarget.value)} placeholder="Charity Name"
                    className="bg-slate-100 rounded-md px-4 py-2 mb-2"/>

                    <div className='text-lg font-medium text-primary-blue mb-1'>
                        Description:
                    </div>
                    <textarea type="text" value={description} onChange={(val) => setDescription(val.currentTarget.value)} placeholder="Description"
                    className="bg-slate-100 rounded-md px-4 py-2 mb-2"/>
                    <button
                    onClick={createCharity} 
                    className="px-4 py-2 bg-red-400 hover:bg-red-300 mt-2 text-white tracking-wide font-medium rounded-md"> 
                        Create Charity
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateCharity;