import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class ReportService {

    async fetchAllReports(){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/report/`, config);
    }
}

export default ReportService;