class Utils{
    addCommaToNumber = (number) => {

        const nm = String(number).split('.');
        let afterDot = '00';
        if(nm.length===2){
            afterDot = nm[1].length===1 ? `${nm[1]}0` : nm[1].substring(0, 2);
        }

        const newNum = String(nm[0]);
        let val = '';
        for(let i=0; i<newNum.length; i++){
            if((newNum.length - (i+1))%3 === 0){
                if(i!==newNum.length-1){
                    val += (newNum[i] + ',');
                }else{
                    val+=newNum[i];
                }
            }else{
                val += newNum[i];
            }
        }
        return `${val}.${afterDot}`;
        // return `${val}`;
    }

    calculatePercentageString = (current, total) => {
        const newVal = (current/total) * 100;
        return newVal.toString().split('.')[0];
    }

    getDateString = (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            return dt.toDateString().split(' ').slice(1).join(', ');
        }
        return "";
    }

    getMonthYearString = (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            const dataArray = dt.toDateString().split(' ').slice(1);
            return dataArray[0] + "-" + dataArray[1] + "-" +  dataArray[2];
        }
        return "";
    }

    getTimeString = (dateString) => {
        if(dateString){
            var dt = new Date(dateString);
            var hours = dt.getHours();
            var minutes = dt.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
        return "";
    }
}

export default Utils;