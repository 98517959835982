import React, { useEffect, useState } from 'react';

import { WithdrawService } from '../../Services';
import { Utils } from '../../Shared';
import WithdrawPopup from './WithdrawPopup';

import { Button } from '../../components/ui/button';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"

import {
    PaginationContent,
    PaginationItem,
    PaginationNext,
    PaginationPrevious,
} from "../../components/ui/pagination"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"

function WithdrawScreen() {
    const withdrawService = new WithdrawService();
    const utils = new Utils();

    const [withdraws, setWithdraws] = useState();
    const [withdrawsData, setWithdrawsData] = useState();

    const [loading, setLoading] = useState(false);

    const [filterStatus, setFilterStatus] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const [selectedWithdraw, setSelectedWithdraw] = useState();

    useEffect(() => {
        const fetchWithdraws = () => {
            setLoading(true);
            withdrawService.getAllWithdrawRequests(currentPage, pageSize, filterStatus).then((res) => {
                console.log(res.data.data);
                setWithdraws(res.data.data.withdraws);
                setWithdrawsData({...res.data.data, withdraws:[]});
            }).catch((err) => {
                console.log(err);
            }).finally(() => setLoading(false));
        }

        fetchWithdraws();
        // eslint-disable-next-line
    }, [currentPage, filterStatus])

    return (
        <div className='w-full min-h-full max-h-screen p-4'>
            {loading && <div>Loading...</div>}

            <div className='flex flex-col items-center w-full'>
                <div className="text-2xl mb-6 font-bold text-primary-blue"> Transactions </div>


                <div className="flex flex-row w-full justify-between px-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline">Filter by Transaction Status</Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuLabel>{!filterStatus ? 'Filter by Status' : filterStatus}</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuRadioGroup value={filterStatus} onValueChange={setFilterStatus}>
                            <DropdownMenuRadioItem value="pending">Pending</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="done">Done</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="">All</DropdownMenuRadioItem>
                            </DropdownMenuRadioGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>


                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious className='cursor-pointer' onClick={(e) => {
                                if(withdrawsData && currentPage>1){
                                    setCurrentPage((prev) => prev-1);
                                }
                            }} />
                        </PaginationItem>
                        {withdrawsData && (
                            <div>
                                <p className='font-medium'>{currentPage} of {withdrawsData?.lastPage ?? currentPage}</p>
                            </div>
                        )}
                        <PaginationItem>
                            <PaginationNext className='cursor-pointer' onClick={(e) => {
                                if(withdrawsData && currentPage<withdrawsData.lastPage){
                                    setCurrentPage((prev) => prev+1);
                                }
                            }} />
                        </PaginationItem>
                    </PaginationContent>
                </div>
            </div>

            {withdraws && (
                <Table>
                    <TableCaption>Withdraw requests</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[40px]">Withdraw ID.</TableHead>
                            <TableHead className=''>Date</TableHead>
                            <TableHead className=''>Last updated</TableHead>
                            <TableHead className='text-center'>Status</TableHead>
                            <TableHead className=''>Currency</TableHead>
                            <TableHead className=''>Amount</TableHead>
                            <TableHead className=''>Action</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {withdraws.length>0 && withdraws.map((withdraw, index) => {
                            return (
                                <TableRow key={withdraw._id}>
                                    <TableCell className="w-[40px]">{withdraw.id}</TableCell>
                                    <TableCell>{utils.getDateString(withdraw.dateCreated)}</TableCell>
                                    <TableCell>{utils.getDateString(withdraw.dateUpdated)}</TableCell>
                                    <TableCell className=''>
                                        <div className="flex flex-row justify-center">
                                            <div className={`px-3 py-0.5 text-sm rounded-full text-white flex flex-row justify-center ${withdraw.status==='pending' ? 'bg-orange-400' : 'bg-green-500'}`}>
                                                <p>{withdraw.status}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{`${withdraw.currency}`.toUpperCase()}</TableCell>
                                    <TableCell className='font-bold'>{utils.addCommaToNumber(withdraw.amount)}</TableCell>
                                    <TableCell>
                                        <button onClick={() => setSelectedWithdraw(withdraw)} className='px-4 py-1 rounded-md bg-blue-600 hover:bg-blue-500 duration-200 flex flex-row justify-center'>
                                            <p className='text-white'>View</p>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

            {selectedWithdraw && <WithdrawPopup withdraw={selectedWithdraw} closePopup={() => setSelectedWithdraw()}/>}
        </div>
    );
}

export default WithdrawScreen;