import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class NotificationService {

    async postNotification(formData){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'application/json'}
        };

        return axios.post(`${Constants.baseUrl}/notification/create`, formData, config);
    }
}

export default NotificationService;