import React, { useState} from 'react';
import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf'; 


// pdfjs.GlobalWorkerOptions.workerSrc = //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js;
function PdfViewerScreen() {
    
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    const [allPages, setAllPages] = useState([]);

    function onDocumentLoadSuccess({ numPages }) {
        let temp = []
        for(var i=1; i<=numPages; i++){
            temp.push(i);
        }
        setAllPages(temp);
    }


    return (
        <div>
            <Document file={"http://localhost:4000/uploaded-file/d8ae8db6-e9e6-40b9-9da4-38b7b5c24e8e2793cf08-f75b-4d3b-89fe-b57f5a9270b7.pdf"}
            loading = {() => {
               return (
                <div> Loading... </div>
               )
            }}
            onLoadSuccess={onDocumentLoadSuccess} 
            onLoadError={(e)=> {
                console.log("**** Error: "+ e);
            }}>
                {allPages.map((pNum) => {
                    return (
                        <Page key={pNum} pageNumber={pNum} />
                    );
                })}
            </Document>
            {/* <p>
                Page {pageNumber} of {numPages}
            </p> */}
        </div>
    );
}

export default PdfViewerScreen;