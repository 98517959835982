import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { FiX, FiEye, FiEyeOff } from "react-icons/fi";

import { UserContext } from '../../Contexts';
import UserService from '../../Services/user.service';
import Cookies from 'js-cookie';

import lomi_icon from '../../assets/lomi-icon.png';

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../components/ui/card"
import { Button } from '../../components/ui/button';
  


function AuthenticationScreen() {
    const navigate = useNavigate();
    const userService = new UserService();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const {setLoggedIn, setUser} = useContext(UserContext);

    const login = () => {
        setErrorMessage();
        const data = {
            email: email,
            password: password
        };

        setLoading(true);
        userService.signIn(data)
        .then((result) => {
            const token = result.data.data.token;
            // console.log(result.data.data);
            Cookies.set('token', token);
            setLoggedIn(true);
            setUser(result.data.data);
            navigate("/");
        }).catch((err) => {
            console.log(err);
            setErrorMessage("UserName / Password not correct!");
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='flex flex-col items-center w-full justify-center h-full mt-20 px-4'>
            <img src={lomi_icon} alt="Lomi Icon" width={100} height={100} className='mb-6' />
            <Card className='w-full md:w-1/2 lg:w-1/4 flex flex-col'>
            <CardHeader>
                <CardTitle>Lomi Funds Login</CardTitle>
                <CardDescription>Adminstrators</CardDescription>
            </CardHeader>
            <CardContent>
                <div className='flex flex-col gap-3'>
                    {errorMessage && 
                    <div className='pl-4 pr-2 py-1 rounded-md bg-red-200 text-[14px] mb-2 flex flex-row justify-between'>
                        <div>{errorMessage}</div>
                        <button onClick={() => setErrorMessage()} className='px-1 py-1'>
                            <FiX size={16}/>
                        </button>
                    </div>}
                    {loading && <div>Logging In...</div>}
                    <input type="email" value={email} onChange={(val) => setEmail(val.currentTarget.value)} placeholder="Email"
                    className="bg-slate-100 rounded-md px-4 py-3 mb-2 w-full"/>
                    <div className='flex flex-row items-center mb-2'>
                        <input type={showPassword ? "text" : "password"} value={password} onChange={(val) => setPassword(val.currentTarget.value)} placeholder="Password"
                        className="bg-slate-100 rounded-md px-4 py-3 flex-grow"/>
                        <button onClick={() => setShowPassword(!showPassword)} className='px-3 py-2'>
                            {!showPassword ? 
                            <FiEye size={18}/> :
                            <FiEyeOff size={18}/>}
                        </button>
                    </div>
                    <Button
                    onClick={login} > 
                        <p>LOGIN</p>
                    </Button>
                </div>
            </CardContent>
            <CardFooter>
                <p className='text-sm text-slate-600'>This admin dashboard is only for Lomi Funds Admins.</p>
            </CardFooter>
                
            </Card>
        </div>
    );
}

export default AuthenticationScreen;