import React, { useEffect, useState } from 'react';
import { FiCalendar, FiX, FiCheck } from "react-icons/fi";

import { Utils, Constants } from '../../Shared';
import { CampaignService } from '../../Services';


function ReportItem({report, onReportPopupClicked}) {
    const utils = new Utils();
    const campaignService = new CampaignService();

    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(false);

    
    const approveCampaign = async (id, value) => {
        setLoading(true);
        campaignService.updateVerification(id, value)
        .then((result) => {
            fetchCampaigns();
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const fetchCampaigns = () => {
        const id = report.campaignId;
        campaignService.fetchCampaignById(id).then((res) => {
            setCampaign(res.data.data);
        }).catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchCampaigns();
    // eslint-disable-next-line
    }, []);

    return (
        <div key={report.campaignId} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2 flex flex-row">
            <div onClick={onReportPopupClicked} className="flex flex-col flex-grow w-full py-3 px-4 rounded-xl gap-2 hover:shadow-lg duration-200">
                {campaign!=null ? (
                    <div className='flex flex-col'>
                        {campaign.photos.length>0 && (
                            <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt="Campaign img" className="w-full h-52 mr-3 object-cover rounded-lg" />
                        )}

                        <div className="flex flex-col flex-grow px-3 py-2">
                            <div className="mr-4 text-xl font-bold text-primary-blue mb-1 line-clamp-1">{campaign.title}</div>
                            <div className="mr-4 text-md text-primary-blue mb-2 line-clamp-1">{campaign.description}</div>
                            <div className="flex flex-row gap-2 mr-4 items-center">
                                <FiCalendar size={14}/>
                                <div className="text-md text-primary-blue font-medium">{utils.getDateString(campaign.dateCreated)}</div>
                            </div>

                            <div className="flex w-full h-1.5 bg-slate-200 my-2 rounded-full overflow-hidden">
                                <div className="flex bg-primary-green rounded-full" 
                                style={{width:`${utils.calculatePercentageString(campaign.currentCollected, campaign.totalAmountNeeded)}%`}}>
                                </div>
                            </div>

                            <div className="flex flex-row items-center mb-2 text-lg gap-2">
                                <div className="text-primary-green font-bold">
                                    {utils.addCommaToNumber(campaign.currentCollected)}
                                    <span className="text-slate-800 font-normal mx-2">collected of</span>
                                    {utils.addCommaToNumber(campaign.totalAmountNeeded)} ETB
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}

                        <div className="flex flex-row">
                            {(campaign.verified===false)
                            ? (
                            <button className="bg-primary-green py-2 px-4 rounded-lg hover:bg-opacity-80 duration-200 flex flex-row items-center gap-2 text-primary-blue" 
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    approveCampaign(campaign._id, true);
                                }}>
                                <FiCheck size={18}/>
                                <div>Approve Campaign</div>
                            </button>
                            ) 
                            : (
                            <button className="bg-primary-blue py-2 px-4 rounded-lg hover:bg-opacity-80 duration-200 flex flex-row items-center gap-2 text-white" 
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    approveCampaign(campaign._id, false);
                                }}>
                                <FiX size={18}/>
                                <div>Suspend Campagin</div>
                            </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col h-96 bg-slate-100 rounded-lg'>
                        
                    </div>
                )}

                <div className="text-primary-blue text-md">
                    <span className='text-red-500 font-bold mx-2 text-lg'>{report.report.count}</span>
                    Users reported this campaign. 
                </div>

                

                
            </div>
            
        </div>
    );
}

export default ReportItem;