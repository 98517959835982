import React, { useEffect, useState } from 'react';

import { UserService, CampaignService, WithdrawService } from '../../Services';
import { Constants, Utils } from '../../Shared';

function WithdrawPopup({withdraw, closePopup}) {
    const userService = new UserService();
    const campaignService = new CampaignService();
    const withdrawService = new WithdrawService();
    const utils = new Utils();

    const [userData, setUserData] = useState();
    const [campaignData, setCampaignData] = useState();
    const [bankDetails, setBankDetails] = useState();

    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUserData = () => {
            userService.getUserInfo(withdraw.userId).then((res) => {
                // console.log(res.data);
                setUserData(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        }

        const fetchCampaignData = () => {
            campaignService.fetchCampaignById(withdraw.campaignId).then((res) => {
                // console.log(res.data);
                setCampaignData(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        }

        const fetchUserAccountDetails = () => {
            withdrawService.getUserBankDetails(withdraw.accountDetailId).then((res) => {
                // console.log(res.data);
                setBankDetails(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
        }

        fetchUserData();
        fetchCampaignData();
        fetchUserAccountDetails();
        // eslint-disable-next-line 
    }, [])

    return (
        <div className='w-full h-full fixed top-0 left-0 z-30 bg-black bg-opacity-20 flex flex-col justify-center items-center'>

            <div className="flex flex-col w-[600px] p-2 px-3 bg-white rounded-lg gap-2">
                <div className="flex flex-row justify-between mb-2">
                    <p className='text-lg font-medium'>Withdraw Details</p>
                    <button onClick={closePopup} className='px-3 bg-red-400 rounded-lg'>
                        <p className='text-white text-sm uppercase'>Close</p>
                    </button>
                </div>

                {campaignData ? (
                    <div className='flex flex-col gap-1 border-[1px] border-slate-300 p-1.5 rounded-xl'>
                        <div className="flex flex-row gap-2 flex-wrap mb-1">
                            {(campaignData.goalEtbAmount ? campaignData.goalEtbAmount!==0 : false) && (
                                <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                    <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaignData.etbAmount)} <span className='font-normal'>ETB</span></span>
                                    <p className='text-sm px-2'>raised of {utils.addCommaToNumber(campaignData.goalEtbAmount??0)} ETB</p>
                                    <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                        <div className='bg-red-400 rounded-full' style={{width:`${utils.calculatePercentageString(campaignData.etbAmount, campaignData.goalEtbAmount)}%`}}></div>
                                    </div>
                                </div>
                            )}

                            {(campaignData.goalUsdAmount ? campaignData.goalUsdAmount!==0 : false) && (
                                <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                    <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaignData.usdAmount)} <span className='font-normal'>USD</span></span>
                                    <p className='text-sm px-2'>raised of {utils.addCommaToNumber(campaignData.goalUsdAmount??0)} USD</p>
                                    <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                        <div className='bg-red-400 rounded-full' style={{width:`${utils.calculatePercentageString(campaignData.usdAmount, campaignData.goalUsdAmount)}%`}}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-row w-full gap-2'>
                            <img src={`${Constants.baseUrl}/uploaded-file/${campaignData.photos[0]}`} alt="Campaign img" className="w-40 h-40 object-cover shrink-0 rounded-lg" />
                            <div className="flex flex-col flex-grow">
                                <p className='text-lg font-bold'>{campaignData.title}</p>
                                <p className='text-sm h-10 overflow-hidden'>{campaignData.description}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='w-full h-20 my-2 bg-slate-200 rounded-lg'>

                    </div>
                )}

                {userData ? (
                    <div className='flex flex-col p-1 px-2 rounded-lg border-[1px] border-slate-300'>
                        <p className='text-lg font-bold'>{userData.firstname} {userData.lastname}</p>
                        <p className='text-base'>{userData.email}</p>
                    </div>
                ) : (
                    <div className='flex flex-col gap-2'>
                        <div className='h-6 w-40 bg-slate-200 rounded-full'></div>
                        <div className='h-4 w-60 bg-slate-200 rounded-full'></div>
                    </div>
                )}

                {bankDetails ? (
                    <div className='flex flex-col py-2 rounded-lg border-[1px] border-slate-300 gap-1'>
                        <div className="flex flex-row w-full">
                            <div className='flex flex-col w-1/2 px-2'>
                                <p className='text-sm'>Bank :</p>
                                <p className='font-medium'>{bankDetails.bankName}</p>
                            </div>
                            <div className='flex flex-col w-1/2 px-2'>
                                <p className='text-sm'>Account Name :</p>
                                <p className='font-medium'>{bankDetails.accountName}</p>
                            </div>
                        </div>
                        <div className="flex flex-row w-full">
                            <div className='flex flex-col w-1/2 px-2'>
                                <p className='text-sm'>Account Number :</p>
                                <p className='font-medium'>{bankDetails.accountNumber}</p>
                            </div>
                            <div className='flex flex-col w-1/2 px-2'>
                                <p className='text-sm'>Phone :</p>
                                <p className='font-medium'>+251-{bankDetails.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col gap-2 p-1 rounded-lg border-[1px] border-slate-300'>
                        <div className='h-6 w-40 bg-slate-200 rounded-full'></div>
                        <div className='h-4 w-64 bg-slate-200 rounded-full'></div>
                        <div className='h-4 w-56 bg-slate-200 rounded-full'></div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default WithdrawPopup;