import axios from "axios";
import { Constants } from "../Shared";
import Cookies from 'js-cookie';


class UserService {
    async signIn(formData){
        return axios.post(`${Constants.baseUrl}/users/login`, formData);
    }

    async signUp(formData){
        return axios.post(`${Constants.baseUrl}/users/register`, formData);
    }

    async getStoredToken(){
        try {
            const token = Cookies.get('token')
            if(token !== null) {
                return token;
            }else{
                return null;
            }
        } catch (e) {
            console.log('Fetching token failed');
            return null;
        }
    }

    async fetchUserData(){
        const token = await this.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/users/user-profile`, config);
    }

    async getUserInfo(id){
        const token = await this.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/users/info/${id}`, config);
    }
}

export default UserService;