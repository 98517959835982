// http://167.99.143.76
const Constants = {
    // baseUrl : 'http://142.93.162.120',
    baseUrl : 'https://api.lomifundsadmin.site',
    categories : [
        {id:0, title:'Event'},
        {id:1, title:'Faith'},
        {id:2, title:'Medical'},
        {id:3, title:'Community'},
        {id:4, title:'Education'},
        {id:5, title:'Children'},
        {id:6, title:'Business'},
        {id:7, title:'Family'},
        {id:8, title:'Emergencies'},
        {id:9, title:'Nonprofit'},
        {id:10, title:'Animals'},
        {id:11, title:'Environment'},
        {id:12, title:'Funeral'},
        {id:13, title:'Competition'},
        {id:14, title:'Creative'},
        {id:15, title:'Sports'},
        {id:16, title:'Travel'},
        {id:17, title:'Other'}   
    ]
}

export default Constants;