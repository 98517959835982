import React, {useContext, useState} from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import { FiChevronRight, FiLogOut, FiUser, FiMenu, FiX } from "react-icons/fi";

// import lomiIcon from "../../assets/lomi-icon.png";
import { UserContext } from '../../Contexts';

function AppBar() {
    const navigate = useNavigate();
    const location = useLocation();

    const [showAppBar, setShowAppBar] = useState(false);

    console.log(location.pathname);
    const {setLoggedIn, setUser, user} = useContext(UserContext);

    const logout = () => {
        Cookies.remove('token');
        setLoggedIn(false);
        setUser(null);
        navigate('/login');
    }

    return (
        <div className={`absolute z-20 md:relative flex flex-col gap-2 justify-start pl-3 pr-3 py-5 w-[250px] h-full border-r-[1px] bg-white ` + (showAppBar ? 'ml-0' : '-ml-[250px] md:ml-0')}>
            <button 
            onClick={() => {
            setShowAppBar(!showAppBar);
            }}
            className="px-2 py-2 rounded-lg bg-slate-200 mr-2 flex md:hidden fixed left-2 top-2 z-30">
                {!showAppBar ? <FiMenu size={20}/>:
                <FiX size={20}/>}
            </button>
            
            <div className="flex flex-row gap-2 items-center pt-6">
                <div className="bg-slate-200 rounded-full p-2">
                    <FiUser size={16} className='shrink-0'/>
                </div>
                <div className="flex flex-col gap-0">
                    <div className="text-lg font-medium">{user!=null ? user.firstname + " " + user.lastname : 'Not Logged In'}</div>
                    <div className="text-sm">{user!=null ? user.email : 'Not Logged In'}</div>
                </div>
            </div>
            <hr />
            <div className='font-medium'>Campaigns:</div>
            <hr />
            <Link onClick={() => setShowAppBar(false)} to="/"
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/' && 'border-primary-green'}`}>
                Home
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/createcampaign" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/createcampaign' && 'border-primary-green'} `}>
                <div className=''>Create Campaign</div>
                <FiChevronRight size={18}/>
            </Link>

            <div className='mt-4 font-medium'>Other Categories:</div>
            <hr />
            <Link onClick={() => setShowAppBar(false)} to="/charity" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/charity' && 'border-primary-green'} `}>
                Charities
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/notification" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/notification' && 'border-primary-green'} `}>
                Notifications
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/tips" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/tips' && 'border-primary-green'} `}>
                Tips
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/transactions" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/transactions' && 'border-primary-green'} `}>
                Transactions
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/reports" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/reports' && 'border-primary-green'} `}>
                Reports
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/logs" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/logs' && 'border-primary-green'} `}>
                Logs
                <FiChevronRight size={18}/>
            </Link>
            <Link onClick={() => setShowAppBar(false)} to="/withdraws" 
            className={`px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between border-2 ${location.pathname==='/withdraws' && 'border-primary-green'} `}>
                Withdraws
                <FiChevronRight size={18}/>
            </Link>
            

            <div className='mt-4 font-medium'>Account:</div>
            <hr />
            <button className="px-4 py-2 rounded-md bg-slate-100 hover:opacity-90 font-medium text-primary-blue flex flex-row items-center justify-between" onClick={logout}>
                Logout
                <FiLogOut size={18}/>
            </button>
        </div>
    );
}

export default AppBar;