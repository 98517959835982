import React, { useEffect, useState } from 'react';
import { SystemService } from '../../Services';

import { Utils } from '../../Shared';

function LogScreen() {
    const systemService = new SystemService();
    const utils = new Utils();

    const [systemLogs, setSystemLogs] = useState([]);

    useEffect(() => {
        // 
        const getSystemLogs = () => {
            systemService.fetchSystemLogs().then((res) => {
                console.log(res.data);
                setSystemLogs([...res.data].reverse());
            }).catch((err) => {
                console.log(err);
            })
        }

        getSystemLogs();
        // eslint-disable-next-line
    }, [])

    const convertToString = (value) => {
        if (typeof value === 'object' && value !== null) {
            let result = '';
            for (const [key, val] of Object.entries(value)) {
                result += `${key}: ${val}, `;
            }
            return result; // Remove the trailing comma and space
        } else {
            return String(value); // Convert non-objects to string
        }
    }

    return (
        <div className='flex flex-col w-full h-screen py-4 px-2'>
            <div className="flex flex-col px-4 text-slate-100 gap-2 bg-black overflow-y-scroll w-full h-full rounded-xl py-2">
                {systemLogs.length>0 && systemLogs.map((log, index) => {
                    return (
                        <div className='flex flex-row gap-4 text-sm'>
                            <p className='w-10 text-right shrink-0'>{index+1}</p>
                            <p className={`uppercase shrink-0 w-14 ${log?.level==='warn' ? 'text-yellow-400' : 'text-red-400'}`}>{log?.level}</p>
                            <p className='text-green-400 w-24 text-left shrink-0'>{utils.getDateString(log?.timestamp) ?? 'unknown time'}</p>
                            <p className='text-green-400 w-16 text-right shrink-0'>{utils.getTimeString(log?.timestamp) ?? 'unknown time'}</p>
                            <p className='text-slate-300'>- {convertToString(log?.message)}</p>
                        </div>
                    );
                })}
                </div>
        </div>
    );
}

export default LogScreen;