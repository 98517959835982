import React, {useState, useEffect} from 'react';

import { TipService } from '../../Services';
import { Utils } from '../../Shared';

// import { Button } from '../../components/ui/button';

import {
    Table,
    TableBody,
    // TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"

import {
    PaginationContent,
    PaginationItem,
    PaginationNext,
    PaginationPrevious,
} from "../../components/ui/pagination"

// import {
//     DropdownMenu,
//     DropdownMenuContent,
//     DropdownMenuLabel,
//     DropdownMenuRadioGroup,
//     DropdownMenuRadioItem,
//     DropdownMenuSeparator,
//     DropdownMenuTrigger,
// } from "../../components/ui/dropdown-menu"

function TipsScreen() {
    const tipService = new TipService();
    const utils = new Utils();

    const [tips, setTips] = useState([]);
    const [tipsData, setTipsData] = useState();

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const getTips = () => {
        setLoading(true);
        tipService.fetchAllTips(currentPage, pageSize)
        .then((result) => {
            console.log(result.data);
            setTips(result.data.data.tips);
            setTipsData({...result.data.data, tips:[]});
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    // const calculateTotal = () => {
    //     let usdAmount = 0;
    //     let etbAmount = 0;
    //     if(tips.length>0){
    //         for(let i=0; i<tips.length; i++){
    //             if(`${tips[i].currency}`.toLowerCase()==='usd'){
    //                 usdAmount += tips[i].amount;
    //             }else{
    //                 etbAmount += tips[i].amount;
    //             }
    //         }
    //     }
    //     setTotalTips({etbAmount, usdAmount});
    // }

    useEffect(() => {
        getTips();
        // eslint-disable-next-line
    }, [currentPage, pageSize]);

    // useEffect(() => {
    //     calculateTotal();
    // // eslint-disable-next-line
    // }, [tips])

    return (
        <div className='flex flex-col items-center w-full min-h-screen mt-10 px-2'>
            <div className="text-2xl mb-6 font-bold text-primary-blue"> Tips Donated </div>

            <div className="flex flex-row flex-wrap justify-center w-[700px] gap-4">
                <div className="flex flex-col w-fit items-center bg-slate-50 flex-grow py-2 rounded-xl hover:scale-105 duration-200 cursor-pointer">
                    <p className='text-primary-green font-bold text-5xl'>{tipsData ? utils.addCommaToNumber(tipsData.etbAmount) : '-'}</p>
                    <p className='text-xl font-medium text-primary-blue'>ETB</p>
                </div>
                <div className="flex flex-col w-fit items-center bg-slate-50 flex-grow py-2 rounded-xl hover:scale-105 duration-200 cursor-pointer">
                    <p className='text-primary-green font-bold text-5xl'>{tipsData ? utils.addCommaToNumber(tipsData.usdAmount) : '-'}</p>
                    <p className='text-xl font-medium text-primary-blue'>USD</p>
                </div>
            </div>


            <div className="flex flex-row w-full justify-between">
                <p className='text-lg'>Total Tips: <span className='font-medium'>({tipsData ? tipsData.total : '-'})</span></p>
                {loading && <div>Loading...</div>}
                {tipsData && tipsData.lastPage>1 && (
                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious className='cursor-pointer' onClick={(e) => {
                                if(tipsData && currentPage>1){
                                    setCurrentPage((prev) => prev-1);
                                }
                            }} />
                        </PaginationItem>
                        {tipsData && (
                            <div>
                                <p className='font-medium'>{currentPage} of {tipsData?.lastPage ?? currentPage}</p>
                            </div>
                        )}
                        <PaginationItem>
                            <PaginationNext className='cursor-pointer' onClick={(e) => {
                                if(tipsData && currentPage<tipsData.lastPage){
                                    setCurrentPage((prev) => prev+1);
                                }
                            }} />
                        </PaginationItem>
                    </PaginationContent>
                )}
            </div>


            {tips && (
                <Table>
                    {/* <TableCaption>Withdraw requests</TableCaption> */}
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[40px]">Id.</TableHead>
                            <TableHead className=''>Name</TableHead>
                            <TableHead className=''>Date</TableHead>
                            <TableHead className=''>Amount</TableHead>
                            <TableHead className=''>Currency</TableHead>
                            {/* <TableHead className=''>Action</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {tips.length>0 && tips.map((tip, index) => {
                            return (
                                <TableRow key={tip._id}>
                                    <TableCell className="w-[40px]">{tip.id}</TableCell>
                                    <TableCell>{tip.firstname} {tip.lastname}</TableCell>
                                    <TableCell>{utils.getDateString(tip.paymentDate)}</TableCell>
                                    <TableCell className='font-medium'>{utils.addCommaToNumber(tip.amount)}</TableCell>
                                    <TableCell>{tip.currency.toUpperCase()}</TableCell>
                                    {/* <TableCell className=''>
                                        <div className="flex flex-row justify-center">
                                            <div className={`px-3 py-0.5 text-sm rounded-full text-white flex flex-row justify-center ${tip.verified===true ? 'bg-green-500' : tip.verified===false ? 'bg-red-400' : 'bg-orange-400'}`}>
                                                <p>{tip.verified===true ? 'Approved' : tip.verified===false ? 'Denied' : 'Pending'}</p>
                                            </div>
                                        </div>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </div>
    );
}

export default TipsScreen;