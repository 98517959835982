import React, { useState } from 'react';
import { useEffect } from 'react';
import { FiX } from "react-icons/fi";

import { CampaignService } from '../../Services';
import { Utils, Constants } from '../../Shared';

function CharityPopup({onClose, charity}) {
    const campaignService = new CampaignService();
    const utils = new Utils();

    const [charityCampaigns, setCharityCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        campaignService.fetchCharityCampaigns(charity.id).then((res) => {
            setCharityCampaigns(res.data.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
// eslint-disable-next-line
    }, []);

    return (
        <div className='inset-0 fixed top-0 flex justify-center items-center bg-black bg-opacity-50 w-full px-4'>
            <div className="flex flex-col px-4 py-3 bg-white rounded-xl w-full md:w-1/2 lg:w-1/3 max-h-[500px] overflow-scroll">
                <div className="flex flex-row w-full justify-between items-center mb-1">
                    <div className="text-xl text-slate-900 font-medium"> {charity.name} </div>
                    <button onClick={onClose} 
                    className="px-2 py-2"> 
                        <FiX size={20} color='#ff0000'/>
                    </button>
                </div>

                <div className='flex flex-col mr-3 flex-grow mb-6'>
                    <img src={`${Constants.baseUrl}/uploaded-file/${charity.logo}`} alt="Carity logo" className='w-[65px] h-[65px] rounded-lg mb-2' />
                    {/* <div className='font-bold'>{charity.name}</div> */}
                    <div className='text-sm'>Created On: {utils.getDateString(charity.dateCreated)}</div>
                    <div>{charity.description}</div>
                </div>
                

                <div className='font-medium text-lg'>Campaigns</div>
                <div className='text-base'>Total: {charityCampaigns.length}</div>

                {loading && <div>Loading...</div>}

                <div className='flex flex-col gap-2 mt-2'>
                    {charityCampaigns.map((campaign) => {
                        return (
                            <div key={campaign.id} className='flex flex-row gap-2 rounded-lg border-[1px] px-2 py-2'>
                                {campaign.photos.length>0 && (
                                    <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt="Campaign img" className="w-20 h-20 mr-3 object-cover rounded-lg" />
                                )}

                                
                                <div className="flex flex-col">
                                    <div className="mr-4 text-lg font-bold text-primary-blue line-clamp-1">{campaign.title}</div>
                                    <div className="mr-4 text-base text-primary-blue line-clamp-1">{campaign.description}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        </div>
    );
}

export default CharityPopup;