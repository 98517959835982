import React, {useContext, useState} from 'react';
import ChooseCharitiesPopup from './ChooseCharityPopup';
import { FiCheck, FiPlus } from "react-icons/fi";

import { useNavigate } from 'react-router-dom';

import { Constants } from '../../Shared';
import { CampaignService } from '../../Services';
import { UserContext } from '../../Contexts';
import { useEffect } from 'react';

function CreateCampaignScreen() {
    const campaignService = new CampaignService();

    const {user} = useContext(UserContext);
    const navigate = useNavigate();

    const [showCharityChoose, setShowCharityChoose] = useState(false);

    const [loading, setLoading] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [etbAmount, setEtbAmount] = useState('');
    const [usdAmount, setUsdAmount] = useState('');
    const [currency, setCurrency] = useState('ETB');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [photo, setPhoto] = useState(null);
    // eslint-disable-next-line
    const [supportingDocs, setSupportingDocs] = useState([]);
    const [videoUrl, setVideoUrl] = useState('');
    const [createdByCharity, setCreatedByCharity] = useState(false);
    const [charityId, setCharityId] = useState('');

    const [selectedCharity, setSelectedCharity] = useState();

    useEffect(() => {
        if(createdByCharity){
            setShowCharityChoose(true);
        }else{
            setShowCharityChoose(false);
            setSelectedCharity();
        }
    }, [createdByCharity]);

    // const getFileNameAndType = (img) => {
    //     let filename = img.name;

    //     let match = /\.(\w+)$/.exec(filename);
    //     let type = match ? `image/${match[1]}` : `image`;

    //     return {name: filename, type: type};
    // }

    const createCampaign = () => {
        let formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('category', selectedCategory);
        formData.append('createdBy', user?.id);
        formData.append('charityId', charityId);
        formData.append('totalAmountNeeded', parseInt(etbAmount)+parseInt(usdAmount));
        formData.append('goalEtbAmount', etbAmount);
        formData.append('goalUsdAmount', usdAmount);
        formData.append('createdByCharity', createdByCharity);
        formData.append('videoUrl', videoUrl);

        // const nmt = getFileNameAndType(photo);
        // formData.append('image', JSON.stringify({ uri: URL.createObjectURL(photo), ...nmt }));
        formData.append('image', photo);

        supportingDocs.forEach((doc, idx) => {
            // const docnmt = getFileNameAndType(doc);
            // formData.append(`doc-${idx}`, JSON.stringify({ uri: URL.createObjectURL(doc), ...docnmt }));
            formData.append(`doc-${idx}`, doc);
        });

        // const vls = formData.values()
        // console.log(URL.createObjectURL(photo));
        
        setLoading(true);
        campaignService.createCampaign(formData)
        .then((response) => {
            const res = response.data;
            console.log(res);
            navigate('/');
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => setLoading(false));
    }

    return (
        <div className='flex flex-col items-center py-10 w-full'>
            <div className="text-2xl mb-6 font-bold text-primary-blue"> Create Campaign </div>
            {loading && <div>Loading...</div>}

            <div className='flex flex-col w-full lg:w-1/2 gap-4 px-4'>
                <div className='flex flex-col'>
                    <div className='text-lg md:text-xl font-medium text-primary-blue'>
                        Is this campaign owned by a charity organization?
                    </div>

                    <div className="flex flex-row gap-3 mt-3">
                        <button className={'px-3 py-2 rounded-lg ' + (createdByCharity ? 'bg-primary-blue text-slate-100' : 'bg-slate-100 text-primary-blue')} 
                        onClick={() => setCreatedByCharity(true)}>
                            YES
                        </button>
                        <button className={'px-3 py-2 rounded-lg ' + (!createdByCharity ? 'bg-primary-blue text-slate-100' : 'bg-slate-100 text-primary-blue')} 
                        onClick={() => {
                            setCharityId(user?.id);
                            setSelectedCharity();
                            setCreatedByCharity(false);
                        }}>
                            NO
                        </button>
                    </div>

                </div>

                {selectedCharity && (
                    <div className='flex flex-col'>
                        <div className='text-lg md:text-xl font-medium text-primary-blue'>
                            Owner Charity:
                        </div>
                        <div className='w-full mt-2'>
                            <div className={'flex flex-row mb-4 items-center px-3 py-3 rounded-lg justify-start border-[2px]'}>
                                {/* <img src={selectedCharity.logo} alt="Carity logo" className='w-16' /> */}
                                <img src={`${Constants.baseUrl}/uploaded-file/${selectedCharity.logo}`} alt="Carity logo" className='w-[50px] h-[50px] rounded-lg mb-2' />
                                <div className='flex flex-col ml-3 mr-3 items-start'>
                                    <div className='font-bold'>{selectedCharity.name}</div>
                                    <div className='line-clamp-1'>{selectedCharity.description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='flex flex-col'>
                    <div className='text-lg md:text-xl font-medium text-primary-blue'>
                        Campaign Category
                    </div>
                    {/* <div className='text-primary-blue text-sm md:text-md line-clamp-2 mb-5'>You can choose the right category for your fundraising.</div> */}

                    <div className="flex flex-wrap gap-2 md:gap-3 mt-5">
                        {Constants.categories.map((itm) => (
                            <div onClick={() => setSelectedCategory(itm.title)} key={itm.id} 
                            className={`px-5 py-2 text-sm md:text-md rounded-full cursor-pointer ${selectedCategory===itm.title ? 'bg-primary-green' : 'bg-slate-100'}`}>
                                {itm.title}
                            </div>
                        ))}
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='text-lg md:text-xl font-medium text-primary-blue'>
                        Name:
                    </div>

                    <input type="text" 
                    value={title} 
                    onChange={(val) => setTitle(val.currentTarget.value)} 
                    placeholder="Title"
                    className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>
                </div>

                <div className='flex flex-col'>
                    <div className='text-lg md:text-xl font-medium text-primary-blue'>
                        Youtube Video Url:
                    </div>

                    <input type="text" 
                    value={videoUrl} 
                    onChange={(val) => setVideoUrl(val.currentTarget.value)} 
                    placeholder="Youtube Video Url"
                    className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>
                </div>

                <div className='flex flex-col'>
                    <div className='text-lg md:text-xl font-medium text-primary-blue'>
                        What is the story?
                    </div>

                    <textarea cols="30" rows="5"
                    value={description} 
                    onChange={(val) => setDescription(val.currentTarget.value)}  
                    placeholder='Campaign Description' 
                    className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none"/>
                </div>

                <div className="flex flex-row gap-2">
                    <div className='flex flex-col flex-grow'>
                        <div className='text-lg md:text-xl font-medium text-primary-blue'>
                            ETB Stating Goal
                        </div>

                        <div className="flex flex-row gap-0">
                            <input type="text" 
                            value={etbAmount} 
                            onChange={(val) => setEtbAmount(val.currentTarget.value)} 
                            placeholder="0"
                            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none text-lg"/>
                        </div>
                    </div>

                    <div className='flex flex-col flex-grow'>
                        <div className='text-lg md:text-xl font-medium text-primary-blue'>
                            USD Stating Goal
                        </div>

                        <div className="flex flex-row gap-0">
                            <input type="text" 
                            value={usdAmount} 
                            onChange={(val) => setUsdAmount(val.currentTarget.value)} 
                            placeholder="0"
                            className="bg-slate-100 rounded-md px-4 py-3 flex-grow rounded-tr-none rounded-br-none text-lg"/>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-2 mb-4">
                    <div className='text-primary-blue text-xl font-medium'>
                        Currency:
                    </div>
                    <div className="relative flex flex-row gap-2">
                        <button onClick={() => {
                            setCurrency('ETB');
                        }}
                        className={`px-6 py-3 rounded-md items-center flex flex-row gap-2 ` + (currency==='ETB' ? 'bg-primary-blue' : 'bg-white')}>
                            {currency==='ETB' && <FiCheck className={`text-lg text-primary-green`}/>}
                            <div className={`text-base tracking-wider font-rubik line-clamp-1 ` + (currency==='ETB' ? 'text-primary-green' : 'text-primary-blue')}>
                                ETB
                            </div>
                        </button>
                        <button onClick={() => {
                            setCurrency('USD');
                        }}
                        className={`px-6 py-3 rounded-md items-center flex flex-row gap-2 ` + (currency==='USD' ? 'bg-primary-blue' : 'bg-white')}>
                            {currency==='USD' && <FiCheck className={`text-lg text-primary-green`}/>}
                            <div className={`text-base tracking-wider font-rubik line-clamp-1 ` + (currency==='USD' ? 'text-primary-green' : 'text-primary-blue')}>
                                USD
                            </div>
                        </button>
                    </div>
                </div>


                <div className='flex flex-col'>
                    <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                        Photo
                    </div>
                    <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                        Photograph that can describe the campaign.
                    </div>

                    {!photo && (
                        <div className="bg-slate-100 rounded-md px-4 py-3 w-60 h-40 flex justify-center items-center">
                            <FiPlus size={30} className="text-slate-400"/>
                        </div>
                    )}

                    {photo && (
                        <img alt="not fount" src={URL.createObjectURL(photo)} className="w-60 h-40 rounded-md object-cover" />
                    )}

                    <input
                        type="file"
                        name="myImage"
                        onChange={(event) => {
                            setPhoto(event.target.files[0]);
                        }}
                    />
                </div>

                <div className='flex flex-col'>
                    <div className='text-xl md:text-2xl font-medium text-primary-blue'>
                        Supporting Documents
                    </div>
                    <div className='text-primary-blue text-md line-clamp-3 mb-3'>
                        Photograph or a .pdf file that can best describe the campaign.
                    </div>

                    <input
                        type="file"
                        name="docs"
                        onChange={(event) => {
                            setSupportingDocs((prev) => [event.target.files[0]]);
                        }}
                    />
                </div>

                <button className='px-5 py-3 mt-3 bg-primary-blue text-primary-green rounded-lg text-lg' onClick={createCampaign}>
                    Create Campaign
                </button>
            </div>

            {showCharityChoose && (
                <ChooseCharitiesPopup 
                onClose={() => {
                    setShowCharityChoose(false);
                    setCreatedByCharity(false);
                    setCharityId('');
                }} 
                onSelected={(charity) => {
                    setCharityId(charity.id);
                    setSelectedCharity(charity);
                    setShowCharityChoose(false);
                }}/>
            )}
        </div>
    );
}

export default CreateCampaignScreen;