import React, {useState} from 'react';

import { NotificationService } from '../../Services';


function PostNotificationScreen() {
    const notificationService = new NotificationService();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(false);

    const postNotification = () => {
        const data = {
            title: title,
            description: description,
            to: 'all',
            type: 'info'
        };

        setLoading(true);
        notificationService.postNotification(data)
        .then((result) => {
            console.log(result.data);
            setTitle('');
            setDescription('');
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='flex flex-col items-center w-full justify-center min-h-screen px-4'>
            <div className="text-xl text-slate-900 mb-6 font-medium"> Post Notification </div>
            <div className='w-full md:w-1/2 lg:w-1/4 flex flex-col'>
                {loading && <div>Loading...</div>}
                <input type="text" value={title} onChange={(val) => setTitle(val.currentTarget.value)} placeholder="Title"
                className="bg-slate-100 rounded-md px-4 py-2 mb-2"/>
                <textarea type="text" value={description} onChange={(val) => setDescription(val.currentTarget.value)} placeholder="Description"
                className="bg-slate-100 rounded-md px-4 py-2 mb-2"/>
                <button
                onClick={postNotification} 
                className="px-4 py-2 bg-red-400 hover:bg-red-300 mt-2 text-white tracking-wide font-medium rounded-md"> 
                    POST NOTIFICATION
                </button>
            </div>
        </div>
    );
}

export default PostNotificationScreen;