import React, { useEffect, useState } from 'react';
import { FiTrash2, FiX, FiCheck, FiCalendar, FiUser } from "react-icons/fi";
import { MdVerified } from "react-icons/md";

import { Utils, Constants } from '../../Shared';
import { UserService } from '../../Services';

import pdfPlaceHolder from '../../assets/pdf.png';

function CampaignItemDetailPopup({campaign, onClose, approveCampaign, deleteCampaign}) {
    const utils = new Utils();
    const userService = new UserService();


    const [userInfo, setUserInfo] = useState(null);
    
    const [selectedImage, setSelectedImage] = useState();

    const getUserInfo = () => {
        // console.log(campaign)
        userService.getUserInfo(campaign.createdBy)
        .then((res) => {
            setUserInfo(res.data.data);
        })
        .catch((err) => console.log(err));
    }

    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line
    }, [])

    return (
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-40'>
            <div className="flex flex-col md:flex-row rounded-2xl bg-white mb-3 overflow-y-scroll p-2 h-full md:h-auto w-[95%] xl:w-2/3 relative">
                <button onClick={() => {
                    setSelectedImage(`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`)
                }} className="flex flex-col w-full md:w-1/3 shrink-0 hover:scale-95 duration-200">
                    {campaign.photos.length>0 && (
                        <img src={`${Constants.baseUrl}/uploaded-file/${campaign.photos[0]}`} alt="Campaign img" className="w-full h-full mr-3 object-cover rounded-lg" />
                    )}
                </button>
                <div className="flex flex-col flex-grow px-3">
                    <div className="flex flex-row gap-4 mb-1 items-center">
                        <div className="text-2xl font-bold text-primary-blue line-clamp-1">{campaign.title}</div>
                        {campaign.verified && <MdVerified size={20} className='text-primary-green'/>}
                    </div>
                    <div className="mr-4 text-lg text-primary-blue mb-3">{campaign.description}</div>

                    <div className="flex flex-row gap-2 flex-wrap my-1">
                        {(campaign.goalEtbAmount ? campaign.goalEtbAmount!==0 : false) && (
                            <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaign.etbAmount)} <span className='font-normal'>ETB</span></span>
                                <p className='text-base px-2'>collected of {utils.addCommaToNumber(campaign.goalEtbAmount??0)} ETB</p>
                                <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                    <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.etbAmount, campaign.goalEtbAmount)}%`}}></div>
                                </div>
                            </div>
                        )}

                        {(campaign.goalUsdAmount ? campaign.goalUsdAmount!==0 : false) && (
                            <div className='text-primary-blue flex flex-col flex-grow items-center bg-slate-100 rounded-lg pt-2 overflow-hidden min-w-fit'>
                                <span className='font-bold mr-1.5 text-xl px-2'>{utils.addCommaToNumber(campaign.usdAmount)} <span className='font-normal'>USD</span></span>
                                <p className='text-base px-2'>collected of {utils.addCommaToNumber(campaign.goalUsdAmount??0)} USD</p>
                                <div className='h-1.5 w-full rounded-full bg-slate-200 flex flex-row mt-1 md:mt-2 '>
                                    <div className='bg-primary-green rounded-full' style={{width:`${utils.calculatePercentageString(campaign.usdAmount, campaign.goalUsdAmount)}%`}}></div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-row gap-2 mr-4 items-center">
                        <FiCalendar size={14}/>
                        <div className="text-base text-primary-blue font-medium">{utils.getDateString(campaign.dateCreated)}</div>
                    </div>

                    <div className="text-primary-blue text-base font-medium mt-2">Created By:</div>
                    <div className="flex flex-row gap-2 py-2 items-center">
                        <div className="w-10 h-10 rounded-full bg-slate-200 flex justify-center items-center">
                            <FiUser size={20} />
                        </div>
                        {userInfo==null 
                        ? (
                            <div className="flex flex-col gap-2">
                                <div className="h-4 w-60 rounded-xl bg-slate-200"></div>
                                <div className="h-3 w-32 rounded-xl bg-slate-200"></div>
                            </div>
                        ) : (
                            <div className="flex flex-col">
                                <div className="text-primary-blue text-base font-medium">{userInfo.firstname} {userInfo.lastname}</div>
                                <div className="text-primary-blue text-sm">{userInfo.email}</div>
                            </div>
                        )}
                    </div>

                    <div className="text-primary-blue text-base font-medium mt-2">Supporting Documents:</div>
                    <div className="text-primary-blue text-sm">Click to open the image/pdf file on a different tab.</div>
                    <div className="flex flex-row flex-wrap gap-3 py-2">
                        {campaign.supportingDocuments.map((doc) => {
                            const isPdf = `${doc}`.split('.')[1]==='pdf';
                            const extension = `${doc}`.split('.')[1];
                            const source = isPdf ? pdfPlaceHolder : `${Constants.baseUrl}/uploaded-file/${doc}`;
                            return (
                                <button key={doc} 
                                onClick={() => {
                                    if(isPdf){
                                        window.open(`${Constants.baseUrl}/uploaded-file/${doc}`);
                                    }else{
                                        setSelectedImage(source);
                                    }
                                }}
                                className="flex flex-row w-fit gap-2 hover:scale-105 duration-200 border-[1px] border-slate-200 hover:border-primary-green p-1 pr-3 rounded-lg">
                                    <img src={source} alt="Campaign img" className="w-12 h-12 mr-3 object-contain rounded-lg" />
                                    <div className="flex flex-col justify-between">
                                        <p className='font-medium'>{isPdf ? 'Document' : 'Image'}</p>
                                        <div className="flex flex-row">
                                            <p className='px-3 text-sm bg-slate-200 rounded-full'>.{extension}</p>
                                        </div>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                    

                    <div className="flex flex-grow"></div>

                    <div className="flex flex-row flex-wrap gap-2 mt-10">
                        <div className="flex flex-col justify-center">
                            <button className="bg-red-500 py-2 px-4 rounded-lg hover:opacity-80 duration-200 flex flex-row items-center gap-2 text-white" 
                            disabled={true} // Remove campaign (add to trash)
                            onClick={() => {
                                deleteCampaign(campaign.id);
                            }}>
                                <FiTrash2 size={18}/>
                                <div>Remove</div>
                            </button>
                        </div>



                        <div className="flex flex-col justify-center">
                            {(campaign.verified===false)
                            ? (
                            <div className="bg-slate-200 text-primary-blue py-2 px-4 rounded-lg duration-200 font-medium flex flex-row items-center gap-2">
                                <FiX size={18}/>
                                Suspended
                            </div>
                            ) 
                            : (
                            <button className="bg-primary-blue py-2 px-4 rounded-lg hover:bg-opacity-80 duration-200 flex flex-row items-center gap-2 text-white" 
                                onClick={() => {
                                    approveCampaign(campaign.id, false);
                                }}>
                                <FiX size={18}/>
                                <div>Suspend</div>
                            </button>
                            )}
                        </div>

                        <div className="flex flex-col justify-center">
                            {campaign.verified 
                            ? (
                            <div className="bg-slate-100 py-2 px-6 rounded-lg duration-200 flex flex-row gap-2 items-center">
                                <FiCheck size={18} className='text-primary-green'/>
                                Approved!
                            </div>
                            ) 
                            : (
                            <button className="bg-primary-green py-2 px-6 rounded-lg hover:bg-opacity-80 duration-200 text-primary-blue flex flex-row items-center gap-2" 
                            onClick={() => {
                                approveCampaign(campaign.id, true);
                            }}>
                                <FiCheck size={18}/>
                                Approve
                            </button>
                            )}
                        </div>
                    </div>
                </div>
                <button onClick={onClose} className="flex flex-row items-center absolute top-4 right-5 bg-red-500 rounded-lg text-white p-1.5 hover:scale-110 duration-200">
                    <FiX size={18}/>
                </button>
            </div>

            {selectedImage && (
                <div className='fixed flex flex-col top-0 left-0 w-full h-screen z-50 bg-black'>
                    <div className="absolute top-0 left-0 flex flex-row w-full justify-between py-1 px-6 items-center">
                        <p className='text-base text-primary-green bg-black px-3 py-1 rounded-lg'>Image Viewer</p>
                        <button className='px-2 py-2 rounded-lg bg-red-500 text-white text-sm uppercase flex flex-row gap-2' onClick={() => setSelectedImage()}>
                            <FiX size={18}/>
                        </button>
                    </div>
                    <div className="flex flex-col flex-grow bg-red w-full h-full bg-black">
                        <img src={selectedImage} alt="" className='rounded-lg object-contain' style={{width:'100%', height:'100%'}} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default CampaignItemDetailPopup;