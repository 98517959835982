import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class WithdrawService {

    async getAllWithdrawRequests(pageNumber=1, pageSize=10, filterStatus=''){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/withdraw/all?pageNumber=${pageNumber}&pageSize=${pageSize}${filterStatus!=='' && `&status=${filterStatus}`}`, config);
    }

    async getUserBankDetails(id){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/withdraw/account/getbyid/${id}`, config);
    }
}

export default WithdrawService;