import React, {useState, useEffect} from 'react';
import { FiX } from "react-icons/fi";

import { CharityService } from '../../Services';
import { Constants } from '../../Shared';

function ChooseCharitiesPopup({onSelected, onClose}) {
    const charityService = new CharityService();

    const [charities, setCharities] = useState([]);
    const [filteredCharities, setFilteredCharities] = useState([]);
    const [selectedCharity, setSelectedCharity] = useState();

    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');

    const getCharities = () => {
        setLoading(true);
        charityService.fetchCharities()
        .then((result) => {
            setCharities(result.data.data);
            setFilteredCharities(result.data.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if(searchText){
            const filtered = charities.filter((ch) => ch.name.toLowerCase().startsWith(searchText.toLowerCase()));
            setFilteredCharities(filtered);
        }else{
            setFilteredCharities(charities);
        }
    // eslint-disable-next-line
    }, [searchText])

    useEffect(() => {
        getCharities();
    // eslint-disable-next-line
    }, []);

    return (
        <div className='inset-0 fixed top-0 flex justify-center items-center bg-black bg-opacity-50'>
            <div className='flex flex-col items-center justify-center bg-white rounded-xl px-4 py-4 w-[500px]'>
                {loading && <div>Loading...</div>}

                <div className='w-full flex flex-row my-2 items-center px-2'>
                    <input type="text" value={searchText} onChange={(val) => setSearchText(val.currentTarget.value)} placeholder="Search"
                    className="bg-slate-100 rounded-lg px-4 py-2 flex-grow"/>
                    <button onClick={onClose} className='px-4 py-2'>
                        <FiX size={18}/>
                    </button>
                </div>

                {filteredCharities.length>0 && (
                    <div className="w-full flex flex-col max-h-[400px] mt-2 overflow-scroll">
                        {filteredCharities.map((charity) => {
                            return (
                                <button className='w-full px-2' onClick={() => {
                                    setSelectedCharity(charity);
                                }}>
                                    <div className={'flex flex-row mb-4 items-center px-3 py-3 rounded-lg justify-start ' + (selectedCharity?.id===charity.id ? 'border-[2px] shadow-sm border-primary-green' : 'border-[2px]')}>
                                        {/* <img src={charity.logo} alt="Carity logo" className='w-16' /> */}
                                        <img src={`${Constants.baseUrl}/uploaded-file/${charity.logo}`} alt="Carity logo" className='w-[50px] h-[50px] rounded-lg mb-2' />
                                        <div className='flex flex-col ml-3 mr-3 items-start'>
                                            <div className='font-bold'>{charity.name}</div>
                                            <div className='line-clamp-1'>{charity.description}</div>
                                        </div>
                                    </div>
                                </button>
                            );
                        })}
                    </div>
                )}

                {selectedCharity && (
                    <button className='px-4 py-2 bg-primary-green text-primary-blue rounded-lg mt-2 font-medium'
                    onClick={() => {
                        onSelected(selectedCharity);
                    }}>
                        CONTINUE
                    </button>
                )}

            </div>
        </div>
    );
}

export default ChooseCharitiesPopup;