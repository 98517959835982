import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class CampaignService {

    async createCampaign(formData){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };
        
        return axios.post(`${Constants.baseUrl}/campaign/create`, formData, config);
    }

    async approveCampaign(formData){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        return axios.post(`${Constants.baseUrl}/campaign/create`, formData, config);
    }

    fetchCampaigns = async (pageNumber=1, pageSize=10, categoryFilters=[], filterStatus='') => {
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        return axios.get(`${Constants.baseUrl}/campaign/allcampaigns?pageNumber=${pageNumber}&pageSize=${pageSize}${(filterStatus && filterStatus!=='') && `&approval=${filterStatus}`}`, config);
    }

    fetchCampaignById = async (id) => {
        return axios.get(`${Constants.baseUrl}/campaign/byid/${id}`);
    }

    fetchCharityCampaigns = async (id) => {
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/campaign/bycharity/${id}`, config);
    }

    updateVerification = async (id, value) => {
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        const data = {
            id: id,
            value: value
        }
        
        return axios.post(`${Constants.baseUrl}/campaign/verify`, data, config);
    }

    deleteCampaign = async (id) => {
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type':'multipart/form-data'}
        };

        return axios.delete(`${Constants.baseUrl}/campaign/${id}`, config);
    }
}

export default CampaignService;