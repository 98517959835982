import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class TipService {

    async fetchAllTips(pageNumber=1, pageSize=10){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/tip/all?pageNumber=${pageNumber}&pageSize=${pageSize}`, config);
    }
}

export default TipService;