import React from 'react';
import { FiX } from "react-icons/fi";

import { Utils } from '../../Shared';

function ReportsPopup({campaignReport, onClose}) {
    const utils = new Utils();
    const reports = campaignReport.report.reports;
    console.log(reports)
    return (
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div className="flex flex-col px-4 py-4 rounded-xl bg-white w-[500px]">
                <div className="flex flex-row justify-between">
                    <div className='text-xl font-bold'>Report Messages</div>
                    <div onClick={onClose} className='p-2 text-red-400 hover:scale-110 hover:cursor-pointer'>
                        <FiX size={22}/>
                    </div>
                </div>
                <div className="flex flex-col gap-2 mt-2">
                    {reports.map((report) => {
                        return (
                            <div className='flex flex-col'>
                                <div className="text-lg font-medium">
                                    {report.message}
                                </div>
                                {report.reportType!=="Other" && (
                                    <div className="text-lg font-medium">
                                        {report.reportType}
                                    </div>
                                )}
                                <div className="text-sm">
                                    {utils.getDateString(report.createdAt)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

        </div>
    );
}

export default ReportsPopup;