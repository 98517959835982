import React, {useEffect, useState} from 'react';
import { CampaignService } from "../../Services";
import { Utils } from '../../Shared';

function HeaderScreen() {
    const utils = new Utils();
    const campaignService = new CampaignService();
    const [campaigns, setCampaigns] = useState([]);
    const [totalCollected, setTotalCollected] = useState(0);
    const [approvedCampaigns, setApprovedCampaigns] = useState(0);

    const fetchCampaigns = async () => {
        campaignService.fetchCampaigns()
        .then((result) => {
        console.log(result.data);
            setCampaigns(result.data.campaigns);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            console.log('Campaigns Fetched!');
        });
    }

    useEffect(() => {
        fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newTotal = 0;
        let apr = 0;
        if(campaigns.length>0){
            campaigns.forEach((camp) => {
                newTotal += camp.currentCollected;
                if(camp.verified===true){
                    apr += 1;
                }
            });
        }
        setTotalCollected(newTotal);
        setApprovedCampaigns(apr);
    }, [campaigns]);

    return (
        <div className="flex flex-row w-full my-2 justify-center gap-2 md:gap-3 lg:gap-8 px-2 md:px-6">
            <div className='flex flex-col items-center gap-2 bg-slate-100 hover:bg-primary-green hover:bg-opacity-50 rounded-xl py-3 px-4 md:px-2 xl:py-6 flex-grow lg:w-1/4 hover:scale-105 duration-200 hover:cursor-pointer'>
                <div className='text-lg md:text-3xl xl:text-5xl text-primary-blue font-bold'>
                    {campaigns.length}
                </div>
                <div className='text-base md:text-xl text-slate-700'>
                    Campaigns
                </div>
            </div>

            <div className='flex flex-col items-center gap-2 bg-slate-100 hover:bg-primary-green hover:bg-opacity-50 rounded-xl py-3 px-4 md:px-2 xl:py-6 flex-grow lg:w-1/4 hover:scale-105 duration-200 hover:cursor-pointer'>
                <div className='text-lg md:text-3xl xl:text-5xl text-primary-blue font-bold text-center'>
                    {utils.addCommaToNumber(totalCollected)} ETB
                </div>
                <div className='text-base md:text-xl text-slate-700'>
                    Donated
                </div>
            </div>

            <div className='flex flex-col items-center gap-2 bg-slate-100 hover:bg-primary-green hover:bg-opacity-50 rounded-xl py-3 px-4 md:px-2 xl:py-6 flex-grow lg:w-1/4 hover:scale-105 duration-200 hover:cursor-pointer'>
                <div className='text-lg md:text-3xl xl:text-5xl text-primary-blue font-bold'>
                    {approvedCampaigns}
                </div>
                <div className='text-base md:text-xl text-slate-700'>
                    Approved
                </div>
            </div>
        </div>
    );
}

export default HeaderScreen;