import React from 'react';
import { useParams } from 'react-router-dom';

import { Constants } from '../../Shared';

function ImageViewer() {
    const { id } = useParams();
    return (
        <div className="flex flex-row w-full justify-center gap-8 min-h-screen items-center">
            <a href='/'> Back </a>
            <img src={`${Constants.baseUrl}/uploaded-file/${id}`} alt="Campaign img" className="w-1/3 h-1/3 mr-3 object-cover rounded-xl" />
        </div>
    );
}

export default ImageViewer;