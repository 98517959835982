import React, {useState, useEffect} from 'react';
import { FiDelete, FiEdit } from "react-icons/fi";

import { CharityService } from '../../Services';
import CreateCharity from './CreateCharity';
import { Utils, Constants } from '../../Shared';

import CharityPopup from './CharityPopup';

function CharitiesScreen() {
    const utils = new Utils();
    const charityService = new CharityService();

    const [charities, setCharities] = useState([]);
    const [filteredCharities, setFilteredCharities] = useState([]);

    const [loading, setLoading] = useState(false);
    const [showCreateCharity, setShowCreateCharity] = useState(false);

    const [clickedCharity, setClickedCharity] = useState();

    const [searchText, setSearchText] = useState('');

    const getCharities = () => {
        setLoading(true);
        charityService.fetchCharities()
        .then((result) => {
            setCharities(result.data.data);
            setFilteredCharities(result.data.data);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    const deleteCharity = (id) => {
        setLoading(true);
        charityService.deleteCharity(id).then((res) => {
            console.log(res.data);
        }).catch((err) => {
            console.log("Error - "+ err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if(searchText){
            const filtered = charities.filter((ch) => ch.name.toLowerCase().startsWith(searchText.toLowerCase()));
            setFilteredCharities(filtered);
        }else{
            setFilteredCharities(charities);
        }
    // eslint-disable-next-line
    }, [searchText])

    useEffect(() => {
        getCharities();
    // eslint-disable-next-line
    }, []);

    return (
        <div className='flex flex-col items-center w-full min-h-screen mt-10'>
            <div className="text-2xl mb-6 font-bold text-primary-blue"> Charities </div>
            {loading && <div>Loading...</div>}
            <button
            onClick={() => setShowCreateCharity(true)} 
            className="px-4 py-2 bg-red-400 hover:bg-red-300 mt-2 text-white tracking-wide font-medium rounded-md"> 
                Create Charity
            </button>

            <div className='text-lg text-primary-blue'>
                <span className='text-primary-green font-bold'> {charities.length}</span> Charities Created
            </div>

            <div className='w-full md:w-1/2 lg:w-1/3 flex flex-row my-2 px-4'>
                <input type="text" value={searchText} onChange={(val) => setSearchText(val.currentTarget.value)} placeholder="Search"
                className="bg-slate-100 rounded-md px-4 py-2 mb-2 flex-grow"/>
            </div>

            {filteredCharities.length>0 && (
                <div className="w-full flex flex-row flex-wrap px-3 mt-2">
                    {filteredCharities.map((charity) => {
                        return (
                            <button onClick={() => setClickedCharity(charity)} className='w-full md:w-1/2 lg:w-1/3 px-2'>
                                <div className='flex flex-row mb-4 items-center px-3 py-3 border-[1px] rounded-xl shadow-sm'>
                                    <img src={`${Constants.baseUrl}/uploaded-file/${charity.logo}`} alt="Carity logo" className='w-[65px] h-[65px] rounded-full' />

                                    <div className='flex flex-col ml-3 mr-3 flex-grow items-start'>
                                        <div className='font-bold'>{charity.name}</div>
                                        <div className='text-sm'>Created On: {utils.getDateString(charity.dateCreated)}</div>
                                        <div className='line-clamp-1'>{charity.description}</div>
                                    </div>
                                    <div className='flex flex-col gap-2 items-end'>
                                        <button className='flex flex-row gap-2 items-center bg-slate-50 hover:bg-slate-100 px-3 py-1 rounded-full'
                                        onClick={() => {
                                            deleteCharity(charity.id);
                                        }}>
                                            <FiEdit size={18} className='text-primary-blue'/>
                                            <div className='text-primary-blue'>
                                                Edit
                                            </div>
                                        </button>
                                        <button className='flex flex-row gap-2 items-center bg-slate-50 hover:bg-slate-100 px-3 py-1 rounded-full'
                                        onClick={() => {
                                            deleteCharity(charity.id);
                                        }}>
                                            <FiDelete size={18} className='text-red-400'/>
                                            <div className='text-red-400'>
                                                Delete
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </button>
                        );
                    })}
                </div>
            )}

            {showCreateCharity && <CreateCharity onClose={() => {
                setShowCreateCharity(false);
                getCharities();
            }}/>}

            {clickedCharity && <CharityPopup onClose={() => setClickedCharity()} charity={clickedCharity}/>}

        </div>
    );
}

export default CharitiesScreen;