import React, {useState, useEffect} from 'react';

import { ReportService } from '../../Services';

import ReportItem from './ReportItem';
import ReportsPopup from './ReportsPopup';

function ReportsScreen() {
    const reportService = new ReportService();

    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);

    const [openReportPopup, setOpenReportPopup] = useState(null);

    const [loading, setLoading] = useState(false);

    const getReports = () => {
        setLoading(true);
        reportService.fetchAllReports()
        .then((result) => {
            setReports(result.data.data);
            console.log(result.data.data)
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if(reports.length>0){
            let mp = new Map();
            for (let index = 0; index < reports.length; index++) {
                // Get month-year string from the transaction date and use it as a key
                const report = reports[index];
                const sessionId = report.campaignId;
                if(mp.has(sessionId)){
                    const currentReportCount = mp.get(sessionId).count + 1;
                    const currentReports = [...mp.get(sessionId).reports, report];
                    mp.set(sessionId, {count:currentReportCount, reports:currentReports});
                }else{
                    mp.set(sessionId, {count:1, reports:[report]});
                }
            }

            const array = Array.from(mp, ([campaignId, report]) => ({ campaignId, report }));

            console.log(array);

            setFilteredReports(array);
        }
    // eslint-disable-next-line
    }, [reports]);

    useEffect(() => {
        getReports();
    // eslint-disable-next-line
    }, []);

    return (
        <div className='flex flex-col items-center w-full min-h-screen mt-10'>
            <div className="text-2xl mb-6 font-bold text-primary-blue"> Reported Campaigns </div>
            {loading && <div>Loading...</div>}

            <div className='text-lg text-primary-blue'>
                <span className='text-primary-green font-bold'> {filteredReports.length}</span> Campaigns got reported
            </div>


            {filteredReports.length>0 && (
                <div className="w-full flex flex-row flex-wrap px-3 mt-2">
                    {filteredReports.map((report) => {
                        return (
                            <ReportItem report={report} onReportPopupClicked={() => setOpenReportPopup(report)}/>
                        );
                    })}
                </div>
            )}

            {openReportPopup!=null && <ReportsPopup campaignReport={openReportPopup} onClose={() => setOpenReportPopup(null)}/>}


        </div>
    );
}

export default ReportsScreen;