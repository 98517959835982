import axios from "axios";
import { Constants } from "../Shared";
import UserService from "./user.service";

const userService = new UserService();

class TransactionsService {

    async getAllSuccessfulTransactions(){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        return axios.get(`${Constants.baseUrl}/transaction`, config);
    }

    async getAllTransactions(pageNumber=1, pageSize=10, filterStatus=''){
        const token = await userService.getStoredToken();

        const config = {
            headers: { Authorization: `Bearer ${token}`}
        };

        console.log(`${Constants.baseUrl}/transaction/all?pageNumber=${pageNumber}&pageSize=${pageSize}${filterStatus!=='' && `&status=${filterStatus}`}`);

        return axios.get(`${Constants.baseUrl}/transaction/all?pageNumber=${pageNumber}&pageSize=${pageSize}${filterStatus!=='' && `&status=${filterStatus}`}`, config);
    }
}

export default TransactionsService;