import React, {useState, useEffect} from 'react';
// import {FiUser } from "react-icons/fi";
// import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

import { TransactionsService } from '../../Services';
import { Utils } from '../../Shared';
import {useIsMobile} from '../shared';

import { LineChart } from '@mui/x-charts/LineChart';

import { Button } from '../../components/ui/button';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"

import {
    PaginationContent,
    PaginationItem,
    PaginationNext,
    PaginationPrevious,
  } from "../../components/ui/pagination"

  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "../../components/ui/dropdown-menu"
  
  

function TransactionsScreen() {
    const transactionsService = new TransactionsService();
    const utils = new Utils();
    const isMobile = useIsMobile();

    const [transactions, setTransactions] = useState([]);
    const [transactionsData, setTransactionsData] = useState();
    const [graphData, setGraphData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState('');
    const pageSize = 10;

    const getTransactions = () => {
        setLoading(true);
        transactionsService.getAllTransactions(currentPage, pageSize, filterStatus)
        .then((result) => {
            // console.log("Transactions");
            console.log(result.data.data);
            setTransactions(result.data.data.transactions);
            setTransactionsData((prev) => {
                return {...result.data.data, transactions:[]}
            });
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        const paidTransactions = transactions.filter((item) => item.paid==='true');

        let mp = new Map();
        for (let index = 0; index < paidTransactions.length; index++) {
            // Get month-year string from the transaction date and use it as a key
            const sessionId = utils.getMonthYearString(paidTransactions[index].paymentDate);
            if(mp.has(sessionId)){
                const currentAmount = mp.get(sessionId) + paidTransactions[index].amount;
                mp.set(sessionId, currentAmount);
            }else{
                const currentAmount = paidTransactions[index].amount;
                mp.set(sessionId, currentAmount);
            }
        }

        const array = Array.from(mp, ([date, amount]) => ({ date, amount })).reverse();

        setGraphData(array);
        console.log(array);
    // eslint-disable-next-line
    }, [transactions]);

    useEffect(() => {
        getTransactions();
        // eslint-disable-next-line
    }, [currentPage, filterStatus]);

    return (
        <div className='flex flex-col items-center w-full min-h-screen mt-10'>
            {loading && <div>Loading...</div>}
            

            <div className='flex flex-col items-center w-full'>
                <div className="text-2xl mb-6 font-bold text-primary-blue"> Transactions </div>


                <div className="flex flex-row w-full justify-between px-2">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline">Filter by Transaction Status</Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56">
                            <DropdownMenuLabel>Filter by Status</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuRadioGroup value={filterStatus} onValueChange={setFilterStatus}>
                            <DropdownMenuRadioItem value="paid">Paid</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="unpaid">Unpaid</DropdownMenuRadioItem>
                            <DropdownMenuRadioItem value="">All</DropdownMenuRadioItem>
                            </DropdownMenuRadioGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>


                    <PaginationContent>
                        <PaginationItem>
                            <PaginationPrevious className='cursor-pointer' onClick={(e) => {
                                if(transactionsData && currentPage>1){
                                    setCurrentPage((prev) => prev-1);
                                }
                            }} />
                        </PaginationItem>
                        {transactionsData && (
                            <div>
                                <p className='font-medium'>{currentPage} of {transactionsData?.lastPage ?? currentPage}</p>
                            </div>
                        )}
                        <PaginationItem>
                            <PaginationNext className='cursor-pointer' onClick={(e) => {
                                if(transactionsData && currentPage<transactionsData.lastPage){
                                    setCurrentPage((prev) => prev+1);
                                }
                            }} />
                        </PaginationItem>
                    </PaginationContent>
                </div>
            </div>


            {transactions.length>0 && (
                <Table>
                    <TableCaption>A list of recent transactions.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[80px]">Invoice No.</TableHead>
                            <TableHead className='w-[150px]'>Date</TableHead>
                            <TableHead className='w-[200px]'>User</TableHead>
                            <TableHead className='w-[100px]'>Status</TableHead>
                            <TableHead className='w-[100px]'>Method</TableHead>
                            <TableHead className='w-[100px]'>Currency</TableHead>
                            <TableHead className="text-right">Amount</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {transactions.length>0 && transactions.map((transaction, index) => {
                            return (
                                <TableRow key={transaction._id}>
                                    <TableCell className="w-[80px]">{((currentPage-1)*pageSize)+(index+1)}</TableCell>
                                    <TableCell>{utils.getMonthYearString(transaction.paymentDate)}</TableCell>
                                    <TableCell>{transaction.firstname??'-'} {transaction.lastname??'-'}</TableCell>
                                    <TableCell>{transaction.paid==='true' ? 'Paid' : 'Un-Paid'}</TableCell>
                                    <TableCell>{transaction.paymentMethod}</TableCell>
                                    <TableCell>{transaction.currency}</TableCell>
                                    <TableCell className="text-right">{transaction.amount}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

            {/* {transactions.length>0 && (
                <div className="w-full flex flex-row flex-wrap px-2 md:px-3 mt-2">
                    {transactions.map((transaction) => {
                        return (
                            <TransactionItem transaction={transaction}/>
                        );
                    })}
                </div>
            )} */}

            {graphData.length>0 && (
                <LineChart
                    title='Last month transactions'
                    xAxis={[
                        { 
                            data: [...graphData.map((item) => new Date(item.date))],
                            label:'Dates',
                            valueFormatter: (value) => {
                                const dt = new Date(value).toISOString();
                                const new_dt = utils.getMonthYearString(dt);
                                return new_dt;
                            }
                         }
                    ]}
                    series={[
                        {
                            data: [...graphData.map((item) => item.amount)]
                        },
                    ]}
                    width={isMobile ?  400 : 600}
                    height={isMobile ?  200 : 300}
                />
            )}


        </div>
    );
}

export default TransactionsScreen;