import React, { useEffect, useState } from 'react';
import { CampaignService } from '../../Services';

import {Utils} from '../../Shared';
import CampaignItemDetailPopup from './CampaignItemDetailPopup';

import { Button } from '../../components/ui/button';

import {
    Table,
    TableBody,
    // TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../components/ui/table"

import {
    PaginationContent,
    PaginationItem,
    PaginationNext,
    PaginationPrevious,
} from "../../components/ui/pagination"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"

function CampaignsList() {
    const campaignService = new CampaignService();
    const utils = new Utils();

    const [campaigns, setCampaigns] = useState();
    const [campaignsData, setCampaignsData] = useState();
    const [loading, setLoading] = useState(false);

    const [filterStatus, setFilterStatus] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const [selectedCampaign, setSelectedCampaign] = useState();

    const fetchCampaigns = () => {
        setLoading(true);
        campaignService.fetchCampaigns(currentPage, pageSize, [], filterStatus).then((res) => {
            console.log(res.data);
            setCampaigns(res.data.campaigns);
            setCampaignsData({...res.data, campaigns:[]});
        }).catch((err) => {
            console.log(err);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchCampaigns();
        // eslint-disable-next-line
    }, [currentPage, filterStatus])

    const approveCampaign = async (id, value) => {
        setLoading(true);
        campaignService.updateVerification(id, value)
        .then((result) => {
            setSelectedCampaign((prev) => {
                return {...prev, verified:value};
            });
            fetchCampaigns();
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
      }
    
    const deleteCampaign = async (id) => {
        setLoading(true);
        campaignService.deleteCampaign(id)
        .then((result) => {
            console.log(result.data);
            fetchCampaigns();
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='w-full h-full p-2'>
            {loading && <p>Loading...</p>}

            <div className='flex flex-col items-center w-full'>
                {/* <div className="text-2xl mb-6 font-bold text-primary-blue"> Campaigns </div> */}


                <div className="flex flex-row w-full justify-between px-2 mb-2">
                    <div className="flex flex-row gap-2 items-center">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline">Filter by Campaign Status</Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56">
                                <DropdownMenuLabel>{!filterStatus ? 'Filter by Status' : filterStatus}</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuRadioGroup value={filterStatus} onValueChange={setFilterStatus}>
                                <DropdownMenuRadioItem value="approved">Approved</DropdownMenuRadioItem>
                                <DropdownMenuRadioItem value="pending">Pending</DropdownMenuRadioItem>
                                <DropdownMenuRadioItem value="denied">Denied</DropdownMenuRadioItem>
                                <DropdownMenuRadioItem value="">All</DropdownMenuRadioItem>
                                </DropdownMenuRadioGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>

                        <div className='px-3 py-1 bg-primary-blue text-white rounded-full'>
                            <p className='uppercase text-[12px]'>{filterStatus ? filterStatus : 'All'}</p>
                        </div>
                    </div>


                    {campaignsData && campaignsData.lastPage>1 && (
                        <PaginationContent>
                            <PaginationItem>
                                <PaginationPrevious className='cursor-pointer' onClick={(e) => {
                                    if(campaignsData && currentPage>1){
                                        setCurrentPage((prev) => prev-1);
                                    }
                                }} />
                            </PaginationItem>
                            {campaignsData && (
                                <div>
                                    <p className='font-medium'>{currentPage} of {campaignsData?.lastPage ?? currentPage}</p>
                                </div>
                            )}
                            <PaginationItem>
                                <PaginationNext className='cursor-pointer' onClick={(e) => {
                                    if(campaignsData && currentPage<campaignsData.lastPage){
                                        setCurrentPage((prev) => prev+1);
                                    }
                                }} />
                            </PaginationItem>
                        </PaginationContent>
                    )}
                </div>
            </div>

            {campaigns && (
                <Table>
                    {/* <TableCaption>Withdraw requests</TableCaption> */}
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[40px]">Id.</TableHead>
                            <TableHead className=''>Title</TableHead>
                            <TableHead className=''>Created on</TableHead>
                            <TableHead className=''>ETB Goal</TableHead>
                            <TableHead className=''>USD Goal</TableHead>
                            <TableHead className='text-center'>Status</TableHead>
                            <TableHead className=''>Action</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {campaigns.length>0 && campaigns.map((campaign, index) => {
                            return (
                                <TableRow key={campaign._id}>
                                    <TableCell className="w-[40px] overflow-hidden">{campaign.id}</TableCell>
                                    <TableCell>{campaign.title}</TableCell>
                                    <TableCell>{utils.getDateString(campaign.dateCreated)}</TableCell>
                                    <TableCell>{utils.addCommaToNumber(campaign.goalEtbAmount)}</TableCell>
                                    <TableCell>{utils.addCommaToNumber(campaign.goalUsdAmount)}</TableCell>
                                    <TableCell className=''>
                                        <div className="flex flex-row justify-center">
                                            <div className={`px-3 py-0.5 text-sm rounded-full text-white flex flex-row justify-center ${campaign.verified===true ? 'bg-green-500' : campaign.verified===false ? 'bg-red-400' : 'bg-orange-400'}`}>
                                                <p>{campaign.verified===true ? 'Approved' : campaign.verified===false ? 'Denied' : 'Pending'}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <button onClick={() => setSelectedCampaign(campaign)} className='px-4 py-1 rounded-md bg-blue-600 hover:bg-blue-500 duration-200 flex flex-row justify-center'>
                                            <p className='text-white'>View</p>
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}

            {selectedCampaign && <CampaignItemDetailPopup onClose={() => setSelectedCampaign()} 
                      campaign={selectedCampaign} fetchCampaigns={fetchCampaigns} approveCampaign={approveCampaign} deleteCampaign={deleteCampaign}/>}

        </div>
    );
}

export default CampaignsList;